@import '../../../../resources/scss/default-import';

.aa-Panel {
  z-index: 9999;
  position: fixed;
  border-radius: unset;

  --aa-icon-color-rgb: rgba($black, 1);
  --aa-muted-color-rgb: rgba($black, 1);
  --aa-selected-color-rgb: unset;
  --aa-panel-shadow: unset;
  --aa-panel-border-color-rgb: rgba($black-30, 1);
  --aa-icon-size: 25px;
}

.aa-ItemActionButton svg {
  width: 25px;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: $black-50;
}

.aa-ItemActionButton svg:hover {
  fill: $black;
}

.recentSearches {
  color: $skyBlue;
}

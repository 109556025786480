@import '../../../../resources/scss/default-import';

.btn {
  display: inline-flex;
  align-items: center;
  margin-left: 6px;
  padding: 7px 26px;
  border-radius: 20px;
  background-color: $black-90;
  font-size: 14px;
  line-height: 25px;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid $black-90;
  cursor: pointer;

  &--primary {
    color: $white;

    &:hover {
      background-color: $black-70;
      border-color: $black-70;
    }

    &:active {
      background-color: $black-50;
      border-color: $black-50;
    }

    &:focus {
      outline: 0;
    }

    &:disabled {
      border: 1px solid $black-20;
      background-color: $black-20;
      color: $white;
      cursor: default;
    }
  }

  &--secondary {
    border: 1px solid $black-90;
    background-color: transparent;
    color: $black-90;

    &:hover {
      border-color: $black-70;
      color: $black-70;
    }

    &:active {
      border-color: $black-50;
      color: $black-50;
    }

    &:focus {
      outline: 0;
    }

    &:disabled {
      border: 1px solid $black-20;
      background-color: transparent;
      color: $black-20;
      cursor: default;
    }
  }

  &--tertiary {
    padding: 0;
    border: none;
    border-bottom: 1px solid $black-90;
    border-radius: 0;
    background-color: transparent;
    color: $black-90;
    font-size: 14px;
    line-height: 21px;
    text-transform: none;

    &:hover {
      border-color: $black-70;
      color: $black-70;
    }

    &:active {
      border-color: $black-50;
      color: $black-50;
    }

    &:focus {
      outline: 0;
    }

    &:disabled {
      color: $black-20;
      border-color: $black-20;
      cursor: auto;
    }
  }
}

html,
body {
  height: 100%;
  width: 100vw;
  min-width: $body-min-width;
}

html {
  box-sizing: border-box;
  text-size-adjust: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;

  &.noscroll {
    position: fixed;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $body-font-family;
  background-color: $body-bg;
  font-size: $body-font-size;
  line-height: $body-line-height;
  color: $black-90;
  min-width: $body-min-width;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &.noscroll {
    position: fixed;
    width: 100%;
  }
}

.layout {
  &--default {
    margin: 0;
  }
}

.medium-up {
  display: none;

  @include breakpoint(768px) {
    display: block;
  }
}

.content {
  @include clearfix();

  //Special padding for the soft layout with actionbar this leads to an end up margin of 80px on the page
  &--soft {
    padding-bottom: 141px;
  }
}

@import '../../../../node_modules/breakpoint-sass/stylesheets/breakpoint';

%h1 {
  font-size: 36px;
  line-height: 45px;
  font-weight: $font-weight-light;

  @include breakpoint($medium) {
    font-size: 45px;
    line-height: 56px;
  }

  @include breakpoint($large) {
    font-size: 60px;
    line-height: 75px;
  }
}

%h2 {
  font-size: 30px;
  line-height: 38px;
  font-weight: $font-weight-light;

  @include breakpoint($medium) {
    font-size: 34px;
    line-height: 43px;
  }

  @include breakpoint($large) {
    font-size: 40px;
    line-height: 50px;
  }
}

%h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: $font-weight-light;

  @include breakpoint($medium) {
    font-size: 26px;
    line-height: 33px;
  }

  @include breakpoint($large) {
    font-size: 30px;
    line-height: 40px;
  }
}

%highlight {
  font-size: 18px;
  line-height: 22px;
  font-weight: $font-weight-light;

  @include breakpoint($large) {
    font-size: 20px;
    line-height: 25px;
  }
}

%text {
  font-size: 16px;
  line-height: 24px;
  font-weight: $font-weight-light;
}

%lead {
  font-size: 18px;
  line-height: 25px;
  font-weight: $font-weight-light;

  @include breakpoint($medium) {
    font-size: 20px;
    line-height: 28px;
  }

  @include breakpoint($large) {
    font-size: 24px;
    line-height: 35px;
  }
}

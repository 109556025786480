@charset "UTF-8";

@import 'default-import';

///* fonts */
@import 'fonts/fonts';

/* layout */
@import 'layouts/default';

/* base styles */
@import 'base/margins';
@import 'base/types';
@import 'layouts/grid-web';
@import 'layouts/component';

/* elements */
@import '../../components/elements/buttons/scss/buttons';

/* algolia autocomplete */
@import 'node_modules/@algolia/autocomplete-theme-classic/dist/theme';

/* algolia dropdown customization */
@import '../../components/x-algolia-search/algolia/styles/siedle-algolia-dropdown-style';

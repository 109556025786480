@font-face {
  font-family: 'Frutiger Neue LT';
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url('../fonts/5656fd28-0c8a-45fd-bd99-f3d798e777c5.eot?#iefix');
  src:
    url('../fonts/5656fd28-0c8a-45fd-bd99-f3d798e777c5.eot?#iefix') format('eot'),
    url('../fonts/d13f4ce3-6d5f-4e11-b7a6-9cde6f1624a0.woff2') format('woff2'),
    url('../fonts/9147fb15-b4da-4721-8ed8-d82293660aec.woff') format('woff'),
    url('../fonts/82654d6a-ec17-4dcc-be94-5092663b0bd2.ttf') format('truetype');
}

@font-face {
  font-family: 'Frutiger Neue LT';
  font-style: normal;
  font-weight: $font-weight-normal;
  src: url('../fonts/eacbf6c2-ef44-4857-b153-3f8fe906e053.eot?#iefix');
  src:
    url('../fonts/eacbf6c2-ef44-4857-b153-3f8fe906e053.eot?#iefix') format('eot'),
    url('../fonts/5d45c016-7f3c-446c-8154-eb18bb0da14c.woff2') format('woff2'),
    url('../fonts/3da96504-eecf-4043-8047-d6645d1fcf0c.woff') format('woff'),
    url('../fonts/b01f2703-fd12-427a-a448-b3ee3214faeb.ttf') format('truetype');
}

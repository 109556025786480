@import '../utilities/functions';
@import '../../../../node_modules/breakpoint-sass/stylesheets/breakpoint';
@import '../../../../node_modules/susy/sass/susy';

.section {
  $half-grid-gutter: $grid-gutter / 2;

  &--reducedWidth {
    max-width: $content-max-width;
    margin-left: auto;
    margin-right: auto;
  }
}

$grid-columns: 12 !default;
$grid-gutter: percentage(1/47) !default;
$half-grid-gutter: $grid-gutter / 2 !default;
$grid-column-width: percentage(3/47) !default;
$grid-max-width: $content-max-width !default;

// set susy defaults
$susy: (
  math: fluid,
  columns: $grid-columns,
  column-width: $grid-column-width,
  container: $grid-max-width,
  gutter: $grid-gutter/$grid-column-width,
  gutter-position: inside-static,
  global-box-sizing: border-box,
);

.row {
  margin-left: -$half-grid-gutter;
  margin-right: -$half-grid-gutter;
  max-width: none;

  &::after {
    content: ' ';
    display: block;
    clear: both;
  }

  &--reducedWidth {
    margin-left: calc(5% - #{$half-grid-gutter});
    margin-right: calc(5% - #{$half-grid-gutter});

    @include breakpoint($small) {
      margin-left: calc(2.5% - #{$half-grid-gutter});
      margin-right: calc(2.5% - #{$half-grid-gutter});
    }

    @include breakpoint(1400px) {
      max-width: $content-max-width + $grid-gutter-max;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.col {
  @include span(12);
}

// grid columns
@include make-grid-columns('col--medium', $medium);
@include make-grid-columns('col--large', $large);

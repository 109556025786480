/* Global heading styles */
@import '../utilities/functions';

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  font-weight: 200;
}

h1 {
  @extend %h1;
}

h2 {
  @extend %h2;
}

h3 {
  @extend %h3;
}

h4 {
  @extend %highlight;
}

/* Global text styles */
p {
  margin: 20px 0;

  @extend %text;
}

/* Global sup element */
sup {
  font-size: 75%;
}

/* Screen reader only */
.sr-only {
  border: 0 none;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
